import Loading from '@boilerplate/components/Loading';
import { Alert, Box } from '@mui/material';
import { addDays } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { useCreateLoggedHourMutation, useGetProjectsQuery } from '@/graphql';
import { dateToHHMM, getHoursMinutesFormattedDate, HHMMToMinutes } from '@/lib/formatTime';
import Notistack from '@/lib/notistack';
import { useAuthenticatedUserStore } from '@/stores/UserStore';

import LoggedHoursFormContainer, { LoggedHoursForm, LoggedHoursFormData, LoggedHoursFormProps } from './LoggedHoursForm';

const useStyles = makeStyles()(() => ({
  formContainer: {
    width: '100%',
  },
}));

type CreateLoggedHoursProps = {
  addendum?: boolean;
  noContainer?: boolean;
  onSubmit?: () => void;
} & Omit<LoggedHoursFormProps, 'handleSubmit'>;

function CreateLoggedHours({ noContainer = false, addendum = false, onSubmit, ...formProps }: CreateLoggedHoursProps) {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();
  const [createLoggedHour] = useCreateLoggedHourMutation();
  const { id: userId } = useAuthenticatedUserStore();

  const { data: projects, loading, error } = useGetProjectsQuery();

  const Component = noContainer ? LoggedHoursForm : LoggedHoursFormContainer;

  const handleSubmit = (data: LoggedHoursFormData, callback?: (success: boolean) => void) => {
    const promise = (async () => {
      const startTime = HHMMToMinutes(data.start);
      const endTime = HHMMToMinutes(data.end);

      const endDate = endTime < startTime ? addDays(data.date, 1) : data.date;
      const projectId = data.project?.id;

      const endDatetime = getHoursMinutesFormattedDate(endDate, HHMMToMinutes(data.end) ? data.end : dateToHHMM());
      const startDatetime = getHoursMinutesFormattedDate(data.date, HHMMToMinutes(data.start) ? data.start : dateToHHMM());

      if (!projectId) {
        throw new Error('logHours:message.error.no_project');
      }

      if (!data.category?.id) {
        throw new Error('logHours:message.error.no_category');
      }

      const foundProject = projects?.projects.items?.find((project) => project.id === projectId);

      // If we can't find the project assume it has started
      if (foundProject) {
        if (foundProject.startDate) {
          const projectStartDate = new Date(foundProject.startDate);

          if (new Date(startDatetime).getTime() < projectStartDate.getTime()) {
            throw {
              customMessage: t('logHours:message.error.project_not_started_yet', {
                startDate: projectStartDate.toLocaleString(i18n.language, { dateStyle: 'short' }),
              }),
            };
          }
        }

        if (foundProject.endDate && new Date(endDatetime).getTime() > new Date(foundProject.endDate).getTime()) {
          throw new Error('logHours:message.error.project_finished');
        }
      }

      let declarableTime = null;

      if (data.declarableTimeHours) {
        declarableTime = data.declarableTimeHours * 60;
      }

      if (data.declarableTimeMinutes) {
        declarableTime = declarableTime ? declarableTime + data.declarableTimeMinutes : data.declarableTimeMinutes;
      }

      return createLoggedHour({
        variables: {
          categoryId: data.category?.id,
          break: HHMMToMinutes(data.break),
          endDatetime,
          startDatetime,
          declarableTime,
          description: data.description,
          projectId: data.project?.id,
          status: null,
          tenantId: data.tenant?.id,
          userId: data.user?.id || userId,
        },
      });
    })();

    promise
      .then(() => {
        Notistack.toast?.(t('logHours:message.success.log'), { variant: 'success' });
        callback?.(true);
        onSubmit?.();
      })
      .catch(({ customMessage, message }) => {
        Notistack.toast?.(customMessage || t([message, 'logHours:message.error.log']), { variant: 'error' });
        callback?.(false);
      });
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    console.error(error);

    return <Alert severity="error">{error.message || error.toString()}</Alert>;
  }

  return (
    <Box className={classes.formContainer}>
      <Component handleSubmit={handleSubmit} addendum={addendum} {...formProps} />
    </Box>
  );
}

export default CreateLoggedHours;
